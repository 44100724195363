import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import SvgIcon from '@mui/material/SvgIcon';

// Icons
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChatIcon from '@mui/icons-material/Chat';
import PortraitIcon from '@mui/icons-material/Portrait';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


// Icônes applis
import ciseaux from '../../Apps/home/icons/ciseaux_small.png'
import bilans from '../../Apps/home/icons/bilans.png'
import eclair from '../../Apps/home/icons/eclair.jpg'


// Icônes dossier Menu/icons
// import calendarBike from './icons/calendar_bike.png' //Calendrier coloré non retenu
import zonesFlag from './icons/LGBT_Rainbow_Flag.png'

import { SideMenu } from './MenuBar.js'

import logo from '../../../logos/logo_blanc_2.png'
import logo_small from '../../../logos/logo_blanc_small.png'

import { Avatar } from '../../Users/Trombinoscope/Effectifs.js'
import { store } from "../../Redux/Store.js"
import { getCoureurFocus } from '../../Redux/CoureurFocusSlice.js'
import { getTrombinesSeason } from '../../Redux/AvatarsSlice.js'
import { getUsersIds, getUsersFonction } from '../../Redux/UsersInfosSlice.js'
import { getUserConnected, isPlatformLocal } from '../../Redux/UserConnectedSlice.js' // getDroitsUserConnected

import { CURRENT_SEASON } from './../../Divers/Const'

import useWindowDimensions from './useWindowDimensions.js'


import './Banner.css'



export function TrombisMesCoureurs(props) {
    let state = store.getState()
    let trombines = getTrombinesSeason(state)
    let usersIds = getUsersIds(state)

    if (props.coureurs === undefined) {
        return (
            // Je ne mets pas null ici c'est pour que la className='trombi_mes_coureurs' reste active pour des questions d'alignement CSS
            <div className='trombi_mes_coureurs'>
            </div>
        )
    } else {
        return (
            <div className='trombi_mes_coureurs'>
                {props.coureurs.map(
                    (coureur, i) =>
                        <div className='avatar_banner' key={i} >
                            <Avatar avatar={trombines?.[coureur]} user={coureur} style={{ borderRadius: '20px' }} link={`/planification/${usersIds?.[coureur]}`} />
                        </div>
                )}
            </div>
        )
    }
}


// Menu Coureur Focus
// Moyen de factoriser le code...
// Les items concernant les coureurs ne sont affichés que pour les coureurs

// Item avec Link, pouvant être item principal ou dans une sous-liste
function LinkItemMenuCoureur(props) {
    return (
        <Link to={props.link} style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={props.onClose} >
            <MenuItem>
                <ListItemIcon>
                    {props.children}
                </ListItemIcon>
                <ListItemText> {props.text} </ListItemText>
            </MenuItem>
        </Link>
    )
}


function MenuCoureurFocus(props) {

    // States booléens pour affichage ou non selon les droits
    const [calendrier, setCalendrier] = useState()
    const [suivi, setSuivi] = useState()
    const [material, setMaterial] = useState()
    const [applications, setApplications] = useState()
    const [gestionCoureurs, setGestionCoureurs] = useState()
    const [admin, setAdmin] = useState()


    useEffect(() => {
        let { droits, Id } = getUserConnected(store.getState())
        let sameIds = (parseInt(props.Id) === parseInt(Id)) // Si l'Id du user connecté et du user focus correspondent

        // Pour le calendrier et le suivi le coureur a le droit d'accès uniquement s'il s'agit de lui-même
        let calendrier_ = sameIds || droits.includes('Calendrier Coureurs WT', 'Calendrier Coureurs Conti', 'Calendrier Coureurs Juniors')
        let suivi_ = sameIds || droits.includes("Suivi d'Activités WT", "Suivi d'Activités Conti", "Suivi d'Activités Juniors")

        // Pour l'instant je ne fais pas de distinction on verra si besoin
        setCalendrier(calendrier_)
        setSuivi(suivi_)
        setMaterial(droits.includes('Matériel WT', 'Matériel Conti', 'Matériel Juniors'))
        setApplications(droits.includes('Applications'))
        setGestionCoureurs(droits.includes('Gestion Coureurs WT', 'Gestion Coureurs Conti', 'Gestion Coureurs Juniors'))
        setAdmin(droits.includes('Admin'))

    }, [props.Id])

    return (
        <Menu id="basic-menu" anchorEl={props.anchor} open={props.open} onClose={props.onClose} >
            {
                (['Cycliste WT', 'Cycliste Conti', 'Cycliste Junior'].includes(props.fonction)) ?
                    <div>
                        {
                            (calendrier) ?
                                <LinkItemMenuCoureur link={`/planification/${props.Id}`} text={'Calendrier'} onClose={props.onClose} >
                                    <CalendarMonthIcon fontSize="small" />
                                </LinkItemMenuCoureur>
                                : null
                        }
                        {
                            (suivi) ?
                                <LinkItemMenuCoureur link={`/liste_activites/${props.Id}`} text={'Suivi d\'Activités'} onClose={props.onClose} >
                                    <ChatIcon fontSize="small" />
                                </LinkItemMenuCoureur>
                                : null
                        }
                        {
                            (material) ?
                                <LinkItemMenuCoureur link={`/materiel/${props.Id}`} text={'Materiel'} onClose={props.onClose} >
                                    <DirectionsBikeIcon fontSize="small" />
                                </LinkItemMenuCoureur>
                                : null
                        }
                        {
                            (applications) ?
                                <>
                                    <LinkItemMenuCoureur link={`/cuts/${props.Id}`} text={"Découpages Séances"} onClose={props.onClose} >
                                        <img style={{ width: '20px', height: '20px' }} src={ciseaux} alt='item' />
                                    </LinkItemMenuCoureur>
                                    <LinkItemMenuCoureur link={`/historique/${props.Id}`} text={"Historique Séances"} onClose={props.onClose}>
                                        <img style={{ width: '20px', height: '20px' }} src={eclair} alt='item' />
                                    </LinkItemMenuCoureur>
                                    <LinkItemMenuCoureur link={`/bilans/${props.Id}`} text={"Bilans"} onClose={props.onClose}>
                                        <img style={{ width: '20px', height: '20px' }} src={bilans} alt='item' />
                                    </LinkItemMenuCoureur>
                                </>
                                : null
                        }
                        {
                            (gestionCoureurs) ?
                                <>
                                    <LinkItemMenuCoureur link={`/zones/${props.Id}`} text={"Zones d'intensité"} onClose={props.onClose}>
                                        <img style={{ width: '20px', height: '20px' }} src={zonesFlag} alt='item' />
                                    </LinkItemMenuCoureur>
                                </>
                                : null
                        }
                    </div>
                    : null
            }
            <LinkItemMenuCoureur link={`/user/${props.Id}`} text={'Profil'} onClose={props.onClose}>
                <PersonIcon fontSize="small" />
            </LinkItemMenuCoureur>
            {
                (admin) ?
                    <div>
                        <LinkItemMenuCoureur link={`/update_user/${props.Id}`} text={"Mise à jour Utilisateur"} onClose={props.onClose}>
                            <ManageAccountsIcon fontSize="small" />
                        </LinkItemMenuCoureur>
                        <LinkItemMenuCoureur link={`/update_avatar/${CURRENT_SEASON}/${props.Id}`} text={"Mise à jour Avatar"} onClose={props.onClose}>
                            <PortraitIcon fontSize="small" />
                        </LinkItemMenuCoureur>
                    </div>
                    : null
            }

        </Menu>
    )
}


// Nom Coureur Focus + ancre pour ouverture MenuCoureurFocus
export function OpenMenuCoureurFocus(props) {
    const handleClick = (event) => { setAnchor(event.currentTarget) }
    const handleClose = () => { setAnchor(null) }
    const [anchor, setAnchor] = useState(null)
    const coureurFocus = useSelector(getCoureurFocus)

    let usersFonctions = getUsersFonction(store.getState())
    let fonction = usersFonctions?.[coureurFocus.coureur]
    // console.log(coureurFocus, fonction)


    if (Object.keys(coureurFocus).length === 0) {
        return null
    } else {
        return (
            <div className='coureur_focus'>
                <IconButton sx={{ p: '10px', '@media (max-width: 1000px)': { p: 0 }, "&:hover": { backgroundColor: "#326dae" } }}
                    onClick={handleClick}>
                    <div className='coureur_focus_txt' > {coureurFocus.coureur} </div>
                    <ArrowDropDownIcon style={{ color: 'white' }} />
                </IconButton>
                <MenuCoureurFocus anchor={anchor} open={Boolean(anchor)} onClose={handleClose} Id={coureurFocus.Id} fonction={fonction} />
            </div>
        )
    }
}
//style={{ color: 'white', fontSize: '11px' }}


// Petit Menu 3 points verticaux en haut à droite
function MenuUserConnected(props) {
    const navigate = useNavigate()

    return (
        <Menu id="basic-menu" anchorEl={props.anchor} open={props.open} onClose={props.onClose} >
            <MenuItem onClick={() => { props.onClose(); navigate('/mon_profil') }}>
                <ListItemIcon>
                    <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Mon Profil</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => { props.onClose(); navigate('/change_password') }}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Changer mon mot de passe</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => { props.onClose(); navigate('/logout') }}>
                <ListItemIcon>
                    <ExitToAppOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Se Déconnecter</ListItemText>
            </MenuItem>
        </Menu>
    )
}


// Gestion du menu associé à l'icône en haut à droite
// Exemples : https://mui.com/material-ui/react-menu/

export function OpenMenuUserConnected(props) {
    const handleClick = (event) => { setAnchor(event.currentTarget) }
    const handleClose = () => { setAnchor(null) }
    const [anchor, setAnchor] = useState(null)

    return (
        <div className='icon_little_menu'>
            <IconButton onClick={handleClick} sx={{ "&:hover": { backgroundColor: "#326dae" } }} >
                <MoreVertIcon sx={{ color: 'white' }} />
            </IconButton>
            <MenuUserConnected anchor={anchor} open={Boolean(anchor)} onClose={handleClose} />
        </div>
    )
}


function OpenMenuSmall() {
    const [anchor, setAnchor] = useState(null)

    const handleClick = (event) => { setAnchor(event.currentTarget) }
    const handleClose = () => { setAnchor(null) }
    const userConnected = getUserConnected(store.getState())

    return (
        <div className='menu_small'>
            <IconButton onClick={handleClick} sx={{ margin: '0 10px 0 5px', "&:hover": { backgroundColor: "#326dae" } }}>
                <MenuIcon style={{ color: 'white' }} />
            </IconButton>
            <Menu
                sx={{ "& .MuiMenu-paper": { backgroundColor: "#00499a", color: 'white', width: '220px' } }}
                id="menu" anchorEl={anchor} open={Boolean(anchor)} onClose={handleClose} >
                <SideMenu userConnected={userConnected} />
            </Menu>
        </div>
    )
}





export default function Banner(props) {
    const { width } = useWindowDimensions()

    const handleDrawerOpen = () => { props.setOpen(true) }

    // useEffect(() => {
    // }, [width])

    return (
        <div className='banner_large'>
            <OpenMenuSmall />
            <div className='icon_open_menu'>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
                    sx={{ "&:hover": { backgroundColor: "#326dae" }, ...(props.open && { display: 'none' }) }} >
                    <ChevronRightIcon />
                </IconButton >
            </div>
            <Link to="/accueil" >
                {(width > 1000) ?
                    <img className='logo_banner' src={logo} alt='logo_gfc' />
                    : <img style={{ width: '34px', marginRight: '10px' }} src={logo_small} alt='logo_gfc' />
                }
            </Link>
            <div className='citation'>
                Notre équipe l'envie de venir, le plaisir de rester.
            </div>
            {(isPlatformLocal(store.getState())) ? <div className='plateforme_locale'> 💻Local💻 </div> : null}

            {/* {height} {width} */}
            <TrombisMesCoureurs coureurs={props.coureurs_coach} />
            <OpenMenuCoureurFocus />
            <OpenMenuUserConnected />
        </div>

    )
}
