// A gérer en bakend !!

// Page d'Accueil en fonction du Rôle
// Composant non prioritaire pourra être fait à la fin et même pas indispensable pour le bon fonctionnement du site...

// Il y aura au moins 4 pages d'Accueil différentes
//  --> Coureur
//  --> Entraineur
//  --> DS
//  --> Autre


// import axios from 'axios'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"

import { getRoleUserConnected } from '../Redux/UserConnectedSlice'
import { store } from "../Redux/Store.js"


const DICO_ROLE_ACCUEIL = {
    'Admin': 'planif_coureurs',
    'Manager Général': 'planif_coureurs',
    'Directeur Performance': 'planif_coureurs',
    'Directeur Entraînement': 'planif_coureurs',
    'Entraineur': 'planif_coureurs',
    'Entraineur Juniors': 'planif_coureurs',
    'Directeur Sportif': 'planif_coureurs',
    'Médecin': 'planif_coureurs',
    'Masseur - Kinésithérapeute': 'planif_coureurs',
    'Masseur - Kinésithérapeute Vacataire': 'planif_coureurs',
    'Préparateur Physique': 'planif_coureurs',

    'Cycliste WT': 'mon_calendrier',
    'Cycliste Conti': 'mon_calendrier',
    'Cycliste Junior': 'mon_calendrier',

    'Directeur Technique et Partenariats': 'materiel_coureurs',
    // "Ingénieur d’Etudes R&D": 'materiel_coureurs',
    'Assistant Technique': 'materiel_coureurs',
    'Assistant Technique - Service Course': 'materiel_coureurs',
    'Assistant Technique Conti': 'materiel_coureurs',
    'Intendant Service Course': 'materiel_coureurs',
}


export default function Accueil() {
    document.title = 'Accueil - Groupama-FDJ'
    const navigate = useNavigate()


    useEffect(() => {
        let role = getRoleUserConnected(store.getState())
        let link = DICO_ROLE_ACCUEIL[role]
        // console.log(role, link)

        if (link !== undefined) {
            navigate(`/${link}`)
        } else{
            navigate('/effectif')
        }
    }, [navigate])

    return (
        // <div>
        //     <h1>Page d'Accueil</h1>
        // </div >
        null
    )
}



